<template>
	<div class="page-content">
		<page-breadcrumb title="List Lesson filtered by Main Topic" class="mb-2" />

		<data-table-ssr id="item_list" ref="item_list" :get-list-fn="getList" :columns="item_fields"
			:filter_schema="filter_schema" :query_default="{ topic_id: topic_id, english_level: null, type: null }"
			@name_on_click="lessonNameOnClick"
		/>
	</div>
</template>
<script>
import lessonService from '../../lesson/service';
import { removeEmpty, safeGet, buildSelectOptions } from '@core/utils/index'
import service from '../service';
const topic_options = [];
const english_level_opts = [];
const SKIL_OPTIONS = [
	{ text: 'Speaking', value: 1 },
	{ text: 'Listening', value: 2 },
	{ text: 'Vocab', value: 3 },
	{ text: 'Conversation', value: 4}
]
const item_fields = [
	{ label: 'Topics', field: 'topic_id', input_type: 'select', options: topic_options },
	{
		label: 'Level', field: 'english_level', input_type: 'select', options: english_level_opts
	},
	{
		label: 'Skills', field: 'type', input_type: 'select', options: SKIL_OPTIONS
	},
	{ label: 'Lessons', field: 'name', display_type: 'translatable-text', click_event: true }
]
const filter_schema = [
	{ cols: 2, fields: [{ label: 'Select Main Topic', field: 'topic_id', input_type: 'select', options: topic_options }] },
	{
		cols: 2, fields: [{
			label: 'Select Level', field: 'english_level', input_type: 'select', options: english_level_opts
		}]
	},
	{
		cols: 2, fields: [{
			label: 'Select Skill', field: 'type', input_type: 'select', options: SKIL_OPTIONS
		}]
	},
]
export default {
	data() {
		return {
			total: 0,
			item_fields,
			filter_schema
		}
	},
	computed: {
		topic_id() {
			return this.$route.params.id;
		}
	},
	created() {
		this.getTopics();
	},
	methods: {
		async getList({ limit, page, query }) {
			query = removeEmpty(query);
			let response = await lessonService.getList({
				query: encodeURIComponent(JSON.stringify(query)),
				page,
				limit,
			});
			let list = []; let total = 0;
			if (response) {
				list = response.list;
				total = response.total;
				let levels = safeGet(() => buildSelectOptions(response.meta['ENGLISH_LEVEL_STRING'], {keyAsValue: true})) || [];
				english_level_opts.length = 0;
				english_level_opts.push(...levels);
			}
			return { list, total }
		},
		async getTopics() {
			let list = await service.getAll();
			let options = list.map((item) => { return { text: item.name, value: item._id } });
			topic_options.push(...options)
		},
		lessonNameOnClick(data){
			this.$router.push({ name: 'learning_lesson-detail', params: {id: data._id} });
		}
	}
}
</script>